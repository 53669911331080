.App {
}
.container {
  width: 100%;
}
.red-text {
  font-size: 10px;
  line-height: 4px;
  position: absolute;
}
.infoIcon {
  color: #9e9e9e;
  font-size: 16px;
  padding-left: 5px;
  cursor: pointer;
}
.material-tooltip {
  border-radius: 4px !important;
  background-color: #ffffff !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px !important;
  color: #323232 !important;
  padding: 15px !important;
  text-align: left !important;
}
@media (min-width: 1920px) {
  .tellius-container {
    width: 1800px;
  }
}

@media (min-width: 1366px) and (max-width: 1919px) {
  .tellius-container {
    width: 1500px;
  }
}

@media (min-width: 1280px) and (max-width: 1365px) {
  .tellius-container {
    width: 1200px;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .tellius-container {
    width: 1100px;
  }
}

@media (max-width: 1023px) {
  .tellius-container {
    width: 800px;
  }
}
.heading {
  height: 28px;
  color: #273238;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}

.heading-desc {
  height: 16px;
  font-size: 14px;
  line-height: 16px;
}

.tellius-label {
  height: 16px;
  color: #273238;
  font-size: 14px;
  line-height: 16px;
}

.tellius-input {
  height: 36px !important;
  border-radius: 2px !important;
  background-color: #e8eef2 !important;
  border: none !important;
  margin-top: 5px !important;
  padding: 0 10px !important;
}

.tellius-button {
  height: 32px !important;
  width: 120px !important;
  border-radius: 27px !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: 0.3px !important;
  line-height: 16px !important;
  text-align: center !important;
  background-color: #4885ed !important;
}

.tellius-link-button {
  color: #4885ed !important;
  font-weight: bold;
  text-transform: capitalize;
  line-height: 17px;
  height: 17px;
}

.tellius-checkbox[type="checkbox"].filled-in:checked + label::after {
  border: 2px solid #4885ed;
  background-color: #4885ed;
  height: 14px;
  width: 14px;
  border-radius: 2px;
  background-color: #e8eef2;
}

.tellius-checkbox-label[type="checkbox"].filled-in:checked
  + span:not(.lever):after {
  border: 2px solid #4885ed;
  background-color: #4885ed;
  height: 14px;
  width: 14px;
  border-radius: 2px;
  background-color: #e8eef2;
}

.tellius-label.tellius-checkbox-label::after {
  border: 2px solid #c1c1c1 !important;
  background-color: #ffffff !important;
  height: 14px;
  width: 14px;
  border-radius: 2px !important;
  background-color: #e8eef2;
}

[type="checkbox"].filled-in:checked + span:not(.lever):before {
  border-right: 2px solid #4885ed !important;
  border-bottom: 2px solid #4885ed !important;
}

.tellius-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  height: 16px;
  color: #8e989e;
  font-size: 14px;
  font-style: italic;
  line-height: 16px;
}
.tellius-input::-moz-placeholder {
  /* Firefox 19+ */
  height: 16px;
  color: #8e989e;
  font-size: 14px;
  font-style: italic;
  line-height: 16px;
}
.tellius-input:-ms-input-placeholder {
  /* IE 10+ */
  height: 16px;
  color: #8e989e;
  font-size: 14px;
  font-style: italic;
  line-height: 16px;
}
.tellius-input:-moz-placeholder {
  /* Firefox 18- */
  height: 16px;
  color: #8e989e;
  font-size: 14px;
  font-style: italic;
  line-height: 16px;
}

.mt5 {
  margin-top: 5px;
}

.imageDiv {
  padding: 30px;
}

.mb0 {
  margin-bottom: 0;
}

.url {
  margin-top: 50px;
  font-weight: bold;
  color: #4885ed;
}

.footer {
  height: 16px;
  color: #8e989e;
  font-size: 14px;
  line-height: 16px;
}

.tal {
  text-align: justify;
  line-height: normal;
}

.ml10 {
  margin-left: 10px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}

.timeExact {
  font-size: 10px;
  font-style: italic;
}

.header-container {
  text-align: center;
  color: #000;
}

.main-title {
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0.69px;
  line-height: 38px;
}

.description {
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 16px;
  color: #273238;
  margin: 10px;
}

.planDetails {
  height: 16px;
  color: #4885ed;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.plan-cards {
  justify-content: center;
  padding: 0 50px;
}
